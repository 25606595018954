import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ProjectModel, TaskModel, Board, Column, VoyageModel  } from './../../models/';
import { TaskThumbnailComponent } from '../task-thumbnail/task-thumbnail.component';
import { ModalService, UpdateVoyageModalContent } from 'src/app/shared';

@Component({
  selector: 'kanban-board',
  templateUrl: './kanban-board.component.html',
  styleUrls: ['./kanban-board.component.css']
})
export class KanbanBoardComponent implements OnInit, OnChanges {
  @Input() filterBy: string;
  @Input() tasks: TaskModel[]
  @Input() project: ProjectModel
  @Input() voyage: VoyageModel
  @Output() onUpdate: EventEmitter<TaskModel> = new EventEmitter();
  @Output() onCreate: EventEmitter<TaskModel> = new EventEmitter();
  @Output() onVoyageUpdate: EventEmitter<VoyageModel> = new EventEmitter();
  visibleTasks: TaskModel[] = [];
  board: Board
  // columnMap: ColumnMap
  columns: Column[] = [ 
    new Column('To Do', []),
    new Column('In Progress', []),
    new Column('In Review', []),
    new Column('Done', [])
  ];
  
  @ViewChild(TaskThumbnailComponent) taskThumbnail: TaskThumbnailComponent;
  elemRef: ElementRef
  
  constructor(private modalService: ModalService) 
  { 
  }

  ngOnChanges(changes: any) {
    console.log('changes', changes)
    console.log("Filtering tasks by: " + this.filterBy.toString());
    if (this.projectOnlyChange(changes)) {
      console.log('Project refreshed!')
      return
    }
    else {
      this.filterTasks(this.filterBy);
      this.populateColumns();
    }
  }
  
  ngOnInit() {
    this.columns = [ 
      new Column('To Do', []),
      new Column('In Progress', []),
      new Column('In Review', []),
      new Column('Done', [])
    ];
    this.populateColumns();
    this.board = new Board(this.project.name, this.columns);
  }

  populateColumns() {
    this.visibleTasks.forEach(task => {
      switch(task.status) {
        case 'todo':
          if(!this.columns[0].tasks.includes(task))
          {
            this.columns[0].tasks.push(task); 
          }
          break;
        case 'inprogress':
          if(!this.columns[1].tasks.includes(task))
          {
            this.columns[1].tasks.push(task); 
          }
          break;
        case 'inreview':
          if(!this.columns[2].tasks.includes(task))
          {
            this.columns[2].tasks.push(task); 
          }
          break;
        case 'done':
          if(!this.columns[3].tasks.includes(task))
          {
            this.columns[3].tasks.push(task); 
          }
          break;
        default:
          throw new Error('Task status unrecognized: ' + task.status);
      }
    })
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      console.log(event.container.data)
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    else {
      // updatedTask contains new unbound task
      // we need to enforce data binding between the new task and the
      // task thumbnail
      // we need to update the corresponding column with the new task
      let updatedTask = this.updatestatus(event);
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      this.updateColumnModel(event, updatedTask);
      console.log("Result of TIM constructor: " + JSON.stringify(updatedTask));
      this.onUpdate.emit(updatedTask);
    }
  }

  // (1) get column index of taskToUpdate
  // (2) get status of the column
  // (3) assign column status to task status
  // (4) replace old task with updated task
  updatestatus(event: CdkDragDrop<string[]>): TaskModel
  {
    let taskToUpdate = new TaskModel(event.item.data)
    let colIdx = parseInt(event.container.element.nativeElement.attributes["index"].value);
    let newstatus = this.columns[colIdx].name;
    taskToUpdate.status = newstatus.toString().toLocaleLowerCase().replace(' ', '');
    return taskToUpdate;
  }

  updateColumnModel(event: CdkDragDrop<string[]>, newTask: TaskModel): void
  {
    let colIdx = parseInt(event.container.element.nativeElement.attributes["index"].value);
    let tasksIdx = this.columns[colIdx].tasks.indexOf(event.item.data);
    this.columns[colIdx].tasks[tasksIdx] = newTask;
  }

  passUpdate(newTask: TaskModel): void 
  {
    if (newTask.status === 'archived') {
      let colIdx = this.columns.length - 1; // done column
      let taskIdx = this.getTaskIdx(newTask, colIdx); 
      if (taskIdx !== -1) {
        this.columns[colIdx].tasks[taskIdx] = new TaskModel(newTask);
      }
    }
    else if (newTask.status === 'backlog') {
      let colIdx = 0; // invariant: only tasks within todo can be added to backlog
      let taskIdx = this.getTaskIdx(newTask, colIdx);
      if (taskIdx !== -1) {
        this.columns[colIdx].tasks[taskIdx] = new TaskModel(newTask);
      }
    }
    else if (newTask.status === 'todo') {
      let colIdx = 0;
      let taskIdx = this.getTaskIdx(newTask, colIdx)
      this.columns[colIdx].tasks[taskIdx] = new TaskModel(newTask);
    }
    else if (newTask.status === 'inprogress') {
      let colIdx = 1;
      let taskIdx = this.getTaskIdx(newTask, colIdx)
      this.columns[colIdx].tasks[taskIdx] = new TaskModel(newTask);
    }
    else if (newTask.status === 'inreview') {
      let colIdx = 2;
      let taskIdx = this.getTaskIdx(newTask, colIdx)
      this.columns[colIdx].tasks[taskIdx] = new TaskModel(newTask);
    }
    else if (newTask.status === 'done') {
      let colIdx = 3;
      let taskIdx = this.getTaskIdx(newTask, colIdx)
      this.columns[colIdx].tasks[taskIdx] = new TaskModel(newTask);
    }
    console.log("Passing updated task to display-user-tasks component...");
    this.onUpdate.emit(newTask);
  }

  getTaskIdx(newTask: TaskModel, colTaskIdx: number): number {
    let taskIdx = 0;
    taskIdx = this.columns[colTaskIdx].tasks.findIndex((task) => {
      return newTask.uuid === task.uuid;
    })
    return taskIdx;
  }

  passCreate(newTask: TaskModel): void
  {
    console.log("Passing new task to display-user-tasks component...")
    this.columns[0].tasks.push(newTask);
    this.onCreate.emit(newTask);
  }

  filterTasks(filter) {
    if (filter === "all") {
      this.visibleTasks = this.tasks.slice(0);
    }
    else {
      this.visibleTasks = this.tasks.filter((task) => {
        return (task.status.toLocaleLowerCase() !== filter[0] && task.status.toLocaleLowerCase() !== filter[1]);
      })
    }
  }

  openVoyageUpdateModal() {
    if (this.project.latestVoyageUuid) {
      let data = {
        voyage: this.voyage,
        project: this.project
      }
      this.modalService.openVoyageModal(UpdateVoyageModalContent, data)
      .then((res) => {
        let incomingVoyage = new VoyageModel(res)
        this.voyage = incomingVoyage
        this.onVoyageUpdate.emit(incomingVoyage)
      })
    }
  }

  projectOnlyChange(changes: any): boolean {
    let hasProject = 'project' in changes
    let hasOnlyOneKey = Object.keys(changes).length === 1
    return hasProject && hasOnlyOneKey
  }
}