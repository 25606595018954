import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { TaskModel } from '../models/TaskModel';
import { ProjectService, TaskService, RouteService, TribeService } from '../shared';
import { ProjectModel, UserModel, VoyageModel } from '../models';

@Component({
  selector: 'app-display-user-tasks',
  templateUrl: './display-user-tasks.component.html',
  styleUrls: ['./display-user-tasks.component.css']
})
export class DisplayUserTasksComponent implements OnInit {
  project: ProjectModel;
  tribe: UserModel[];
  projectParam: string;
  activeVoyage: VoyageModel
  tasks: TaskModel[];
  filterBy: Array<string> = ["archived","backlog"];
  pageSize: number = 100;

  constructor(private routeService: RouteService, 
    private taskService: TaskService,
    private projectService: ProjectService,
    private tribeService: TribeService,
    private cdRef: ChangeDetectorRef) {
    this.routeService.queryParams.subscribe(params => {
      this.projectParam = params['project'];
    });

    this.taskService.tasksReturned.subscribe((value) => {
      //this listens for subsequent get task requests for lazy loading
      this.tasks = value
    });
  }

  async ngOnInit() {
    
    if(this.projectService.getProject(this.projectParam) === undefined)
    {
      await this.projectService.getProjects();    
    }

    // initialize project
    this.project = this.projectService.getProject(this.projectParam);
    console.log('this.project', this.project)

    // initialize the tribe
    this.tribe = await this.tribeService.getTribe(this.projectParam);

    this.taskService.getTasksLazy(this.project, this.pageSize, null)
    .then((res) => {
      this.tasks = res;
    }).catch((err) => {
        console.log('Error: ' + JSON.stringify(err));
    });

    // initialize voyage
    if (this.project.latestVoyageUuid !== '') {
      console.log('Getting active voyage...')
      this.activeVoyage = this.projectService.getVoyage(this.project.uuid, this.project.latestVoyageUuid)
    }
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  pushTask(task: TaskModel): void {
    this.taskService.pushTask(task, this.project)
    .then((task) => {
      console.log('this.tasks', this.tasks)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  updateTask(updatedTask: TaskModel): void
  {
    let tagsChanged = false;
    let idx = this.tasks.findIndex((task) => {
      return task.uuid === updatedTask.uuid
    })
    tagsChanged = this.tasks[idx].tags !== updatedTask.tags
    this.taskService.updateTask(updatedTask, idx, this.project)
    .then((statusCode) => {
      // TODO: determine if chaining a project update is required
      // for now, refresh project state on every task update
      if (statusCode === 205) {
        console.log('New tags detected!')
        return this.projectService.getProjects(true)
      }
      else {
        return Promise.reject(0);
      }
    })
    .then((projects) => {
      console.log('chained projects', projects)
      console.log('this.project', this?.project)
      let target = projects.find((project) => {
        return this.projectParam === project.uuid
      })
      this.project = target
    })
    .catch((err) => {
      if (err !== 0) {
        console.error(err)
      } 
    })
  }

  updateVoyage(updatedVoyage: VoyageModel): void 
  {
    this.projectService.updateVoyage(this.project, updatedVoyage, 'none')
  }

  // handleNewFormControls(): Promise<>

  tasksLoaded(): boolean {
    return !!this.tasks
  }

  updateLocalTaskReference(updatedTask: TaskModel): number {
    let idx = this.tasks.findIndex((task) => {
      return task.uuid === updatedTask.uuid;
    })
    console.log("Index of updated task: " + idx.toString());
    if (idx !== -1)
      this.tasks[idx] = new TaskModel(updatedTask);
    return idx
  }
}
